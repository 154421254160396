<template>
  <v-footer id="dashboard-core-footer">
    <cookie-law ref="cookieBanner">
      <div slot-scope="props">
        <v-btn text color="error" dark small fab @click="closeCookie">
          <v-icon>close</v-icon>
        </v-btn>
        <span class="text--primary body-1">
          {{ $t('MeeTeeMeeNgern1.messageCookieLaw') }}
        </span>
        <router-link class="body-1 text-decoration-underline" to="/cookie-policy-private">{{ $t('MeeTeeMeeNgern1.messageCookieLaw2') }}</router-link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <v-row align="center" class="pt-0">
          <v-col cols="12" sm="4" md="2" class="pa-0">
            <v-switch v-model="necessaryCookies" class="caption" hide-details>
              <template v-slot:label>
                <div><span class="text--primary body-2">คุกกี้ที่จำเป็น</span></div>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="pa-0">
            <v-switch v-model="analyticCookies" hide-details>
              <template v-slot:label>
                <div><span class="text--primary body-2">คุกกี้เพื่อการวิเคราะห์</span></div>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="pa-0">
            <v-switch v-model="functionalCookies" hide-details>
              <template v-slot:label>
                <div><span class="text--primary body-2">คุกกี้เพื่อช่วยในการใช้งาน</span></div>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" sm="4" md="3" class="pa-0">
            <v-switch v-model="targetingCookies" hide-details>
              <template v-slot:label>
                <div><span class="text--primary body-2">คุกกี้เพื่อปรับเนื้อหาให้เข้ากับกลุ่มเป้าหมาย</span></div>
              </template>
            </v-switch>
          </v-col>
          <v-btn @click="declineCookie" outlined color="error" class="body-1" small>ปฎิเสธ</v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn @click="acceptCookie(false)" outlined color="success" class="body-1" small><span>บันทีกความยินยอม</span></v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn @click="acceptCookie(true)" outlined color="success" class="body-1" small><span>ยอมรับทั้งหมด</span></v-btn>
        </v-row>
      </div>
    </cookie-law>
  </v-footer>
</template>

<script>
  import CookieLaw from 'vue-cookie-law'
  export default {
    name: 'DashboardCoreFooter',
    components: { CookieLaw },
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        necessaryCookies: true,
        analyticCookies: false,
        functionalCookies: false,
        targetingCookies: false,
      }
    },
    mounted () {
    },
    computed: {
      areCookiesAccepted () {
        return this.$refs.cookieBanner.isAccepted()
      }
    },
    methods: {
      closeCookie () {
        this.$refs.cookieBanner.isOpen = false
      },
      declineCookie () {
        localStorage.setItem('cookie:accepted', false)
        localStorage.removeItem('cookies')
        this.$refs.cookieBanner.isOpen = false
      },
      acceptCookie (allCookies) {
        // if (this.areCookiesAccepted === null) {
        //   var cookies = {
        //     'Necessary Cookies': this.necessaryCookies,
        //     'Analytic Cookies': this.analyticCookies,
        //     'Functional Cookies': this.functionalCookies,
        //     'Targeting Cookies': this.targetingCookies
        //   }
        //   localStorage.setItem('cookies', JSON.stringify(cookies))
        // }
        // if (this.areCookiesAccepted) {
        //   alert(this.areCookiesAccepted)
        // }
        if (allCookies) {
          this.analyticCookies = true
          this.functionalCookies = true
          this.targetingCookies = true
        }
        var cookies = {
          'Necessary Cookies': this.necessaryCookies,
          'Analytic Cookies': this.analyticCookies,
          'Functional Cookies': this.functionalCookies,
          'Targeting Cookies': this.targetingCookies
        }
        localStorage.setItem('cookie:accepted', true)
        localStorage.setItem('cookies', JSON.stringify(cookies))
        this.$refs.cookieBanner.isOpen = false
      },
    }
  }
</script>